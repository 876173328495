import { SET_MANAGER } from "../../constantes/redux";
import { UserManaged, ManagerActionType } from "./../../types/user";

const initialState: UserManaged = {
  data: null,
};

export default function managerReducer(
  state = initialState,
  action: ManagerActionType
) {
  switch (action.type) {
    case SET_MANAGER:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
}
