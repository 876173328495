import { UserManaged, UserManagedActionType } from "../../types/user";
import { SETUSERMANAGED } from "../../constantes/redux";

const initStateUserManaged: UserManaged = {
  data: null,
};

export function userManagedReducer(
  state = initStateUserManaged,
  action: UserManagedActionType
) {
  switch (action.type) {
    case SETUSERMANAGED:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
}
