import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";

const uRI = process.env.REACT_APP_API_URL_BACKEND;
const uRICryptoRating = process.env.REACT_APP_API_URL_CRYPTO_RATING;

// Axios instance for public routes
const OtherRouteAxios = axios.create({
  baseURL: uRI,
});

// Axios instance for private routes
const CryptoRatingRouteAxios = axios.create({
  baseURL: uRICryptoRating,
});

// Request interceptor for API calls
const RouteAxios = axios.create({
  baseURL: uRI,
});

RouteAxios.interceptors.request.use(
  async (config: AxiosRequestConfig<any>) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config as InternalAxiosRequestConfig<any>;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
RouteAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: any) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("token_refresh");
        if (refreshToken) {
          const response = await axios.post(`${uRI}/token/refresh`, {
            refresh: refreshToken,
          });
          if (response.status === 200) {
            const newAccessToken = response.data.access;
            // const newRefreshToken = response.data.refresh;

            localStorage.setItem("token", newAccessToken);
            // localStorage.setItem("token_refresh", newRefreshToken);

            // Update the original request with the new token
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${newAccessToken}`;

            // Retry the original request with the new token
            return axios(originalRequest);
          }
        } else {
          localStorage.clear();
          window.location.href = "/";
          return Promise.reject(error);
        }
      } catch (refreshError) {
        // console.error("Refresh token error:", refreshError);
        localStorage.clear();
        window.location.href = "/";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export { OtherRouteAxios, RouteAxios, CryptoRatingRouteAxios };
