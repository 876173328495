import { SET_ASSET_DATA } from "../../constantes/redux";
import { TAssetDataAction, TAssetDataState } from "../../types/asset";

const initialState: TAssetDataState = {
  data: null,
};

export default function assetDataReducer(
  state = initialState,
  action: TAssetDataAction
) {
  switch (action.type) {
    case SET_ASSET_DATA:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
}
