const color = {
  bluePrimary: "#321fdb",
  blueSecondary: "#356ae6",
  blueThirdly: "#06179A",
  green: "#00c853",
  red: "#d50000",
  info: "#00b0ff",
  white: "#ffffff",
  black: "#000000",
  grey: "#ebedef",
  greyLight: "#ccc",
  purple: "#6d1691",
  warning: "#ff9100",
  linear: "linear-gradient(145deg, #61baf5 0%, #629ff7 100%)",
};

export default color;
