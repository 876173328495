import axios from "axios";
import { TCurrency, TLivePrice, TLogo } from "../types/others";
import { CryptoRatingRouteAxios, RouteAxios } from "./axios";

export async function getUsdPrice(asset: string): Promise<number> {
  let price: number = 1;
  try {
    const url: string = `https://api.coinbase.com/v2/prices/${asset}-USD/spot`;
    const response = await axios.get(url);
    price = response.data.data.amount;
  } catch (error) {
    console.error(error);
  }
  return parseFloat(price.toString());
}

export const getLogoCrypto = async (symbol: string) => {
  return (
    await CryptoRatingRouteAxios.get<TLogo>(`/cryptorating/logo/${symbol}`)
  ).data;
};

export const getCurrecieEurUsdt = async () => {
  return (
    await axios.get<TCurrency>(
      "https://api.binance.com/api/v3/ticker/price?symbol=EURUSDT"
    )
  ).data;
};

export const getLivePrice = async () => {
  return (await RouteAxios.get<TLivePrice>("/live-price")).data;
};
