import {
  TLivePrice,
  TTimeMachine,
  TLivePriceAction,
  IToggleEuroDollar,
  TDisplayPortfolio,
  TTimeMachineAction,
  InvalidateDataSeeting,
  IToggleEuroDollarAction,
  TDisplayPortfolioAction,
  InvalidateDataSeetingActionType,
} from "../../types/others";
import {
  SET_CURRENCY,
  SET_LIVE_PRICE,
  SET_DISPLAY_PORTFOLIO,
  SET_TOGGLE_EURO_DOLLAR,
  SET_INVALIDATE_DATA_SEETING,
  SET_TIME_MACHINE,
} from "../../constantes/redux";

const initialState: InvalidateDataSeeting = {
  changement: false,
};

const initialStateEurDollar: IToggleEuroDollar = {
  currency: "$",
  valueEuroDollar: 0.92,
};

const initialStateLivePrice: TLivePrice = {};

const initialStateDisplayPortfolio: TDisplayPortfolio = {
  isActive: false,
  date: null,
};

const initialStateTimeMachine: TTimeMachine = {
  isActive: false,
  startDate: null,
  endDate: null,
};

export function invalidateDataSeetingReducer(
  state = initialState,
  action: InvalidateDataSeetingActionType
) {
  switch (action.type) {
    case SET_INVALIDATE_DATA_SEETING:
      return {
        ...state,
        changement: action.payload.changement,
      };
    default:
      return state;
  }
}

export function euroDollarReducer(
  state = initialStateEurDollar,
  action: IToggleEuroDollarAction
) {
  switch (action.type) {
    case SET_TOGGLE_EURO_DOLLAR:
      return {
        ...state,
        valueEuroDollar: action.payload.valueEuroDollar,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload.currency,
      };
    default:
      return state;
  }
}

export function livePricerReducer(
  state = initialStateLivePrice,
  action: TLivePriceAction
) {
  switch (action.type) {
    case SET_LIVE_PRICE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function displayPortfolioReducer(
  state = initialStateDisplayPortfolio,
  action: TDisplayPortfolioAction
) {
  switch (action.type) {
    case SET_DISPLAY_PORTFOLIO:
      return {
        isActive: action.payload.isActive,
        date: action.payload.date,
      };
    default:
      return state;
  }
}

export function timeMachineReducer(
  state = initialStateTimeMachine,
  action: TTimeMachineAction
) {
  switch (action.type) {
    case SET_TIME_MACHINE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
