import { CircularProgress, CircularProgressProps } from "@mui/material";

export default function Loading({
  size = 20,
  color = "primary",
}: {
  size?: number;
  color?: CircularProgressProps["color"];
}) {
  return <CircularProgress size={size} color={color} thickness={3} />;
}

// import React from "react";
// import "./index.css"; // Assurez-vous d'importer votre fichier CSS

// interface LoadingProps {
//   size?: number; // Taille des barres
// }

// const Loading: React.FC<LoadingProps> = ({ size = 30 }) => {
//   return (
//     <div className="loading-bars">
//       <div className="bar" style={{ height: size }}></div>
//       <div className="bar" style={{ height: size }}></div>
//       <div className="bar" style={{ height: size }}></div>
//     </div>
//   );
// };

// export default Loading;
