import { CURRENCY } from "../constantes/query";
import { useQuery } from "@tanstack/react-query";
import { getCurrecieEurUsdt } from "../services/other";

export const useGetCurrency = () => {
  return useQuery({
    queryKey: CURRENCY,
    queryFn: () => getCurrecieEurUsdt(),
    refetchInterval: 60000,
  });
};
