import { UserType, UserActionType } from "./../../types/user";
import { SET_USER, SET_CONNECTED } from "../../constantes/redux";

const initialState: UserType = {
  data: null,
  connected: false,
};

export default function userReducer(
  state = initialState,
  action: UserActionType
) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        data: action.payload.data,
      };
    case SET_CONNECTED:
      return {
        ...state,
        connected: action.payload.connected,
      };
    default:
      return state;
  }
}
